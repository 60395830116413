@font-face {
  font-family: "Phoebe";
  src: url("../fonts/PhoebeV4.ttf");
}

@font-face {
  font-family: "PhoebeJP";
  src: url("../fonts/PhoebeJP.ttf");
}
@font-face {
  font-family: "ChibiPhoebe";
  src: url("../fonts/ChibiPhoebe.ttf");
}
@font-face {
  font-family: "Phoebe Condensed Bold";
  src: url("../fonts/PhoebeCondesnedB.ttf");
}

/* Typography & Base Styling */

* {
  // animation: name duration timing-function delay iteration-count direction fill-mode;
  box-sizing: border-box;
  font-size: inherit;
  text-rendering: geometricPrecision;
  font-weight: 100;
  image-rendering: optimizeSpeed; /* Older versions of FF          */
  image-rendering: -moz-crisp-edges; /* FF 6.0+                       */
  image-rendering: -webkit-optimize-contrast; /* Safari                        */
  image-rendering: -o-crisp-edges; /* OS X & Windows Opera (12.02+) */
  image-rendering: pixelated; /* Awesome future-browsers */
}

html {
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

body {
  font-family: "Phoebe", "Monaco", monospace;
  font-size: 22px;

  line-height: 1.3;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  color: #444;
  margin: 0;
  background: black;
}

.pointer {
  cursor: pointer;
}

#root {
  width: 100%;
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  max-width: 133.333vh;
  max-width: calc(var(--vh, 1vh) * 133.33);
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.japanese {
  .FileReader {
    h2 {
      font-family: "PhoebeJP", sans-serif;
    }
  }
  .tooltips p {
    line-height: calc(var(--scene-width) / 20);
    font-size: calc(var(--scene-width) / 20);
    font-family: "PhoebeJP", sans-serif;
    padding-bottom: 8px;
  }
}
